import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'

import FullPage from '../../../components/full-page'
import Header from '../../../components/header'
import loader from '../../../components/loader'
import AuthStore from '../../../modules/user/stores/auth-store'
import { H4 } from '../../../styles/typography'
import Other from './components/other'
import Password from './components/password'
import Social from './components/social'
import { LoginEl } from './elements'

const Login = ({ embed, toggleRegister }) => {
  const redirected = useRef(false)
  const router = useRouter()
  const { loading, loggedIn } = AuthStore.useContainer()

  useEffect(() => {
    ;(async () => {
      if (loggedIn && !redirected.current && !embed) {
        redirected.current = true
        await router.push(router.query?.redirect || '/discover')
      }
    })()
  }, [embed, loggedIn, router])

  return loader(
    loading,
    false,
    <FullPage type={embed ? 'disabled' : 'show-header'}>
      <LoginEl>
        {!embed ? (
          <Header subtitle='Welcome back to LivDemy.' title='Sign In' />
        ) : (
          <H4>Sign In</H4>
        )}
        <Social />
        <Password />
        <Other embed={embed} toggleRegister={toggleRegister} />
      </LoginEl>
    </FullPage>,
  )
}

export default Login

Login.propTypes = {
  embed: PropTypes.bool,
  toggleRegister: PropTypes.func,
}

Login.defaultProps = {
  embed: false,
  toggleRegister: () => {},
}
