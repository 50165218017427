/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'

import Button from '../../../../components/button'
import { accent, background1 } from '../../../../styles/colors'
import { hFlex, square, vFlex } from '../../../../styles/style-helpers'

export const LoginEl = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: auto;
  background: ${background1};

  > h4 {
    width: 100%;
    padding: 0 1rem;
    margin-bottom: 1em;
  }

  .social {
    position: relative;
    width: 100%;
    padding: 0 1em;

    > p {
      margin-bottom: 2.5em;
      color: #a0a0a0;
      line-height: 1.33;
    }

    .buttons {
      width: 100%;
      ${hFlex};
      justify-content: space-between;
    }
  }

  .password {
    width: 100%;
    padding: 1em;

    > p {
      margin: 1.5em 0;
      color: #a0a0a0;
      line-height: 1.33;
    }

    form {
      width: 100%;
      ${vFlex};
      align-items: flex-end;

      button {
        margin-top: 1em;
        width: 10em;
      }
    }
  }

  .other {
    position: relative;
    width: 100%;
    padding: 3em 1em 1em;
    ${hFlex};
    justify-content: space-between;

    a,
    button {
      text-align: left;

      p {
        line-height: 1.33;

        span {
          display: block;
          width: fit-content;
          color: ${accent};
          text-decoration: underline;
        }
      }

      &:nth-child(2) {
        p {
          ${vFlex};
          align-items: flex-end;
        }
      }
    }
  }
`

export const SocialButtonEl = styled(Button)`
  background: ${({ type }) =>
    type === 'google'
      ? '#CF4332'
      : type === 'fb'
      ? '#1877F2'
      : 'unset'} !important;
  color: white !important;
  width: calc(100%);

  &.Mui-disabled {
    opacity: 0.5;
  }

  .MuiButton-startIcon {
    ${square('1.25em')};
    ${vFlex};
    margin: 0 1em;
  }
`
