import * as Sentry from '@sentry/browser'
import { useGoogleLogin } from 'react-google-login'

import google from '../../../../assets/icons/google.svg'
import useToggle from '../../../../hooks/use-toggle'
import AuthStore from '../../../../modules/user/stores/auth-store'
import { Text } from '../../../../styles/typography'
import { SocialButtonEl } from '../elements'

const Social = () => {
  const { googleAuth } = AuthStore.useContainer()

  const [hideSocial, toggleHideSocial] = useToggle(false)

  const { signIn, loaded } = useGoogleLogin({
    clientId: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
    onFailure: (e) => {
      toggleHideSocial(true)
      Sentry.captureException(e)
    },
    onSuccess: googleAuth(false),
    prompt: 'select_account',
  })

  return hideSocial ? null : (
    <div className='social'>
      <Text>
        You can use your Google or Facebook accounts to sign in to LivDemy
        securely without a password.
      </Text>
      <div className='buttons'>
        <SocialButtonEl
          disabled={!loaded}
          onClick={signIn}
          /* eslint-disable-next-line @next/next/no-img-element */
          startIcon={<img alt='Google Icon' src={google} />}
          type='google'
        >
          Sign in with Google
        </SocialButtonEl>
      </div>
    </div>
  )
}

export default Social
