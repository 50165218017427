import Link from 'next/link'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { S05 } from '../../../../styles/typography'
import Button from '../../../../components/button'

const Other = ({ embed, toggleRegister }) => {
  const {
    query: { redirect },
  } = useRouter()

  return (
    <div className='other'>
      {embed ? (
        <Button onClick={() => toggleRegister()}>
          <S05>
            New User? <span>Register here.</span>
          </S05>
        </Button>
      ) : (
        <Link
          href={redirect ? `/register?redirect=${redirect}` : '/register'}
          passHref
        >
          <a>
            <S05>
              New User? <span>Register here.</span>
            </S05>
          </a>
        </Link>
      )}

      {!embed && (
        <Link href='/forgot-password/request' passHref>
          <a>
            <S05>
              Forgot password? <span>Click here.</span>
            </S05>
          </a>
        </Link>
      )}
    </div>
  )
}

export default Other

Other.propTypes = {
  embed: PropTypes.bool,
  toggleRegister: PropTypes.func,
}

Other.defaultProps = {
  embed: false,
  toggleRegister: () => {},
}
