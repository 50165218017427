import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { object, string } from 'yup'

import Button from '../../../../components/button'
import TextField from '../../../../components/form/TextField'
import AuthStore from '../../../../modules/user/stores/auth-store'
import { Text } from '../../../../styles/typography'

const Password = () => {
  const { passwordLogin } = AuthStore.useContainer()

  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(
      object({
        email: string()
          .email('Enter a valid Email-ID')
          .required('Enter your Email-ID'),
        password: string()
          .min(6, 'Use at least 6 characters')
          .required('Enter your password'),
      }),
    ),
  })

  const onSubmit = ({ email, password }) => passwordLogin(email, password)

  return (
    <div className='password'>
      <Text>Sign in with your password:</Text>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          error={Boolean(errors.email)}
          helperText={errors.email?.message}
          InputProps={{
            autoComplete: 'email',
          }}
          inputRef={register}
          label='Email-ID'
          name='email'
          type='email'
        />

        <TextField
          error={Boolean(errors.password)}
          helperText={errors.password?.message}
          InputProps={{
            autoComplete: 'current-password',
          }}
          inputRef={register}
          label='Password'
          name='password'
          type='password'
        />

        <Button disabled={isSubmitting} theme='primary' type='submit'>
          {isSubmitting ? 'Signing in...' : 'Sign In'}
        </Button>
      </form>
    </div>
  )
}

export default Password
