import { TextField as TextFieldComponent } from '@material-ui/core'
import styled from 'styled-components'

const TextField = styled(TextFieldComponent)`
  width: 100%;
  margin: 0.5em 0 !important;

  label {
    color: #999 !important;
  }

  input {
    color: #e7e7e7;
  }

  .MuiInput-underline:after {
    border-bottom: 2px solid #fff;
  }

  .MuiInput-underline:before {
    border-bottom: 1px solid #a0a0a0;
  }
`

export default TextField
